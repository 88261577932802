<template>
  <div>
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button icon="fa fa-plus" @click="insertEvent()">新增</vxe-button>
      </template>
    </vxe-toolbar>

    <vxe-table
      border
      resizable
      row-key
      show-overflow
      highlight-hover-row
      ref="xTable"
      :data="tableData.rows"
      :loading="loading"
    >
      <vxe-table-column type="seq" width="60"></vxe-table-column>
      <vxe-table-column field="title" title="产品"></vxe-table-column>
      <vxe-table-column title="工序">
        <template v-slot="{ row }">
          <b-badge variant="light" v-for="p in row.procedures" :key="p.id"
            >{{ p.title }}
          </b-badge>
        </template>
      </vxe-table-column>
      <vxe-table-column
        field="created"
        title="创建时间"
        formatter="dateTime"
      ></vxe-table-column>
      <vxe-table-column
        field="updated"
        title="修改时间"
        formatter="dateTime"
      ></vxe-table-column>
      <vxe-table-column title="操作" width="100" show-overflow>
        <template v-slot="{ row }">
          <vxe-button
            type="text"
            icon="fa fa-edit"
            @click="editEvent(row)"
          ></vxe-button>
          <vxe-button
            type="text"
            icon="fa fa-trash-o"
            @click="removeEvent(row)"
          ></vxe-button>
        </template>
      </vxe-table-column>
    </vxe-table>

    <vxe-pager
      perfect
      :current-page.sync="tableData.page"
      :page-size.sync="tableData.pageSize"
      :total="tableData.totalResult"
      @page-change="pageChange"
    >
    </vxe-pager>

    <vxe-modal
      v-model="showEdit"
      :title="selectRow ? '编辑&保存' : '新增&保存'"
      width="600"
      min-width="600"
      min-height="300"
      :loading="submitLoading"
      resize
      destroy-on-close
    >
      <template v-slot>
        <vxe-form
          :data="formData"
          :rules="formRules"
          title-align="right"
          title-width="100"
          @submit="submitEvent"
        >
          <vxe-form-item v-bind="formItems[0]"></vxe-form-item>
          <vxe-form-item v-bind="formItems[1]">
            <Procedure
              ref="procedures"
              :data="formData.procedures"
              v-on:editProcedures="editProcedures"
            ></Procedure>
          </vxe-form-item>
          <vxe-form-item v-bind="formItems[2]"></vxe-form-item>
        </vxe-form>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
import Product from '../models/product'
import ProductService from '../services/product.service'
import Procedure from './ProductProcedure'

export default {
  components: {
    Procedure
  },
  data() {
    return {
      loading: false,
      submitLoading: false,
      tableData: {},
      selectRow: null,
      showEdit: false,
      formData: {
        id: null,
        title: null,
        procedures: null
      },
      formRules: {
        title: [
          { required: true, message: '请输入名称' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符' }
        ]
      },
      formItems: [
        {
          field: 'title',
          title: '名称',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '请输入名称' } }
        },
        {
          field: 'procedures',
          title: '工序',
          span: 24
        },
        {
          align: 'center',
          span: 24,
          titleAlign: 'left',
          itemRender: {
            name: '$buttons',
            children: [
              { props: { type: 'submit', content: '提交', status: 'primary' } }
            ]
          }
        }
      ]
    }
  },
  async mounted() {
    this.loading = true
    try {
      const response = await ProductService.get()
      this.tableData = response.data
    } catch {
      this.tableData = {}
    }
    this.loading = false
  },
  methods: {
    insertEvent() {
      this.formData = {
        title: ''
      }
      this.selectRow = null
      this.showEdit = true
    },
    editEvent(row) {
      console.log(row)
      this.formData = {
        id: row.id,
        title: row.title,
        procedures: row.procedures
      }
      this.selectRow = row
      this.showEdit = true
    },
    removeEvent(row) {
      this.$XModal.confirm('您确定要删除该数据?').then(async (type) => {
        if (type === 'confirm') {
          this.loading = true
          const product = new Product()
          Object.assign(product, row)
          try {
            await ProductService.delete(product)
            this.showEdit = false
            const response = await ProductService.get(
              this.tableData.page,
              this.tableData.pageSize
            )
            this.tableData = response.data
            // this.$refs.xTable.remove(row)
          } catch {}
          this.loading = false
        }
      })
    },
    async submitEvent() {
      if (!(await this.$refs.procedures.fullValidEvent())) {
        return
      }
      this.submitLoading = true
      const product = new Product()
      Object.assign(product, this.formData)
      const {
        insertRecords,
        removeRecords,
        updateRecords
      } = this.$refs.procedures.getRecordset()
      insertRecords.forEach((item, index, arr) => {
        delete arr[index].id
      })
      product.insertRecords = insertRecords
      product.removeRecords = removeRecords
      product.updateRecords = updateRecords
      delete product.procedures

      if (this.selectRow) {
        try {
          const response = await ProductService.update(product)
          this.showEdit = false
          Object.assign(this.selectRow, response.data.data)
        } catch {}
      } else {
        try {
          const response = await ProductService.create(product)
          this.showEdit = false
          this.$refs.xTable.insert(response.data.data)
        } catch {}
      }
      this.submitLoading = false
    },
    async pageChange({ currentPage, pageSize }) {
      this.loading = true
      try {
        const response = await ProductService.get(currentPage, pageSize)
        this.tableData = response.data
      } catch {
        this.tableData = {}
      }
      this.loading = false
    },
    editProcedures(procedures) {
      this.formData.procedures = procedures
    }
  }
}
</script>
