<template>
  <div>
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button icon="fa fa-plus" @click="insertEvent(-1)">新增</vxe-button>
      </template>
    </vxe-toolbar>

    <vxe-table
      border
      show-overflow
      keep-source
      ref="xTable"
      class="my_table_insert"
      max-height="400"
      :data="tableData"
      :edit-rules="validRules"
      :edit-config="{
        trigger: 'click',
        mode: 'cell',
        showStatus: true,
        icon: 'fa fa-pencil-square-o'
      }"
    >
      > >
      <vxe-table-column
        field="title"
        title="工序"
        :edit-render="{ name: 'input', autoselect: true }"
      ></vxe-table-column>
      <vxe-table-column
        field="order"
        title="排序"
        :edit-render="{
          name: '$input',
          autoselect: true,
          props: { type: 'number' }
        }"
      ></vxe-table-column>
      <vxe-table-column
        field="price"
        title="价格"
        :edit-render="{
          name: '$input',
          autoselect: true,
          props: { type: 'float', digits: 3 }
        }"
      ></vxe-table-column>

      <vxe-table-column title="操作" width="60" show-overflow>
        <template v-slot="{ row }">
          <vxe-button
            type="text"
            icon="fa fa-trash-o"
            @click="removeEvent(row)"
          ></vxe-button>
        </template>
      </vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>
import Procedure from '../models/procedure'

export default {
  props: ['data'],
  data() {
    return {
      tableData: this.$props.data,
      validRules: {
        title: [
          { required: true, message: '请输入名称' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符' }
        ],
        order: [{ required: true, message: '排序必须填写' }],
        price: [{ required: true, message: '价格必须填写' }]
      }
    }
  },
  methods: {
    async insertEvent(row) {
      const record = new Procedure()
      const { row: newRow } = await this.$refs.xTable.insertAt(record, row)
      await this.$refs.xTable.setActiveCell(newRow, 'title')
    },
    removeEvent(row) {
      this.$refs.xTable.remove(row)
    },
    getRecordset() {
      return this.$refs.xTable.getRecordset()
    },
    async fullValidEvent() {
      const errMap = await this.$refs.xTable
        .fullValidate()
        .catch((errMap) => errMap)
      if (errMap) {
        const msgList = []
        Object.values(errMap).forEach((errList) => {
          errList.forEach((params) => {
            const { rowIndex, column, rules } = params
            rules.forEach((rule) => {
              msgList.push(
                `第 ${rowIndex} 行 ${column.title} 校验错误：${rule.message}`
              )
            })
          })
        })
        this.$XModal.message({
          status: 'error',
          message: () => {
            return [
              <div class="red" style="max-height: 400px;overflow: auto;">
                {msgList.map((msg) => (
                  <div>{msg}</div>
                ))}
              </div>
            ]
          }
        })
        return false
      } else {
        return true
      }
    }
  }
}
</script>
