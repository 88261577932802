var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vxe-toolbar',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('vxe-button',{attrs:{"icon":"fa fa-plus"},on:{"click":function($event){return _vm.insertEvent(-1)}}},[_vm._v("新增")])]},proxy:true}])}),_c('vxe-table',{ref:"xTable",staticClass:"my_table_insert",attrs:{"border":"","show-overflow":"","keep-source":"","max-height":"400","data":_vm.tableData,"edit-rules":_vm.validRules,"edit-config":{
      trigger: 'click',
      mode: 'cell',
      showStatus: true,
      icon: 'fa fa-pencil-square-o'
    }}},[_vm._v(" > > "),_c('vxe-table-column',{attrs:{"field":"title","title":"工序","edit-render":{ name: 'input', autoselect: true }}}),_c('vxe-table-column',{attrs:{"field":"order","title":"排序","edit-render":{
        name: '$input',
        autoselect: true,
        props: { type: 'number' }
      }}}),_c('vxe-table-column',{attrs:{"field":"price","title":"价格","edit-render":{
        name: '$input',
        autoselect: true,
        props: { type: 'float', digits: 3 }
      }}}),_c('vxe-table-column',{attrs:{"title":"操作","width":"60","show-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('vxe-button',{attrs:{"type":"text","icon":"fa fa-trash-o"},on:{"click":function($event){return _vm.removeEvent(row)}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }